import React from "react";

import CardSection from "./CardSection";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import CheckoutFormStyles from "./CheckoutForm.module.css";

class CheckoutForm extends React.Component {
  componentDidMount() {
    console.log(this.props);
  }
  handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(this.props.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          address: {
            city: this.props.city,
            country: "US",
            line1: this.props.address,
            postal_code: this.props.postalCode,
            state: this.props.state,
          },
          email: this.props.email,
          name: this.props.name,
        },
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);

      //change to error step

      this.props.errorStep();
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        console.log(result.paymentIntent.status);
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.

        //change to success step
        this.props.successStep();
      }
    }
  };

  render() {
    return (
      <div className={CheckoutFormStyles.step3}>
        <h2>Confirm your order</h2>
        <h3>Billing details</h3>
        <div className={CheckoutFormStyles.billingContainer}>
          <p>{this.props.name}</p>
          <p>{this.props.email}</p>
          <p>{this.props.address}</p>
          <p>
            {this.props.postalCode}, {this.props.city}
          </p>
          <p>{this.props.state}, USA</p>
        </div>
        <p
          id={CheckoutFormStyles.returnToCheckout}
          onClick={this.props.backToDetails}
        >
          Return to checkout details
        </p>
        <form onSubmit={this.handleSubmit}>
          <CardSection />

          <div>
            <div id={CheckoutFormStyles.orderSummaryContainer}>
              <h2 id={CheckoutFormStyles.orderSummary}>Order Summary</h2>
            </div>
            <div id={CheckoutFormStyles.orderContainer}>
              <p>Total:</p>
              <p>${this.props.amount}</p>
            </div>

            <button
              disabled={!this.props.stripe}
              type="submit"
              className={CheckoutFormStyles.primaryBtn}
            >
              Pay ${this.props.amount}
            </button>
          </div>
        </form>
      </div>
    );
  }
}
export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          backToDetails={props.backToDetails}
          clientSecret={props.clientSecret}
          elements={elements}
          amount={props.amount}
          email={props.email}
          name={props.name}
          address={props.address}
          postalCode={props.postalCode}
          city={props.city}
          state={props.state}
          successStep={props.successStep}
          errorStep={props.errorStep}
        />
      )}
    </ElementsConsumer>
  );
}
