import React from "react";
import { Link } from "gatsby";
import queryString from "query-string";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "../components/CheckoutForm";
import Head from "../components/head";
import donateImg from "../images/donateImg.jpg";
import checkmark from "../images/checkmark.png";
import errorMark from "../images/errorMark.png";
import close from "../images/close.png";

import donateStyles from "./donate.module.css";

// require("dotenv").config({
//   path: `.env`,
// })

class DonatePage extends React.Component {
  state = {
    amount: "",
    email: "",
    notANumber: false,
    name: "",
    address: "",
    postalCode: "",
    city: "",
    state: "",
    step: "1",
    clientSecret: "",
    queryString: {},
  };

  componentDidMount() {
    this.setState({
      queryString: queryString.parse(this.props.location.search),
    });
   
  }
  hideError = () => {
    this.setState({ notANumber: false });
  };
  stepOneHandleClick = () => {
    let isThisANumber = !!this.state.amount.match(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/
    );
    if (isThisANumber === true) {
      this.setState({ step: "2" });
    } else {
      this.setState({ notANumber: true });
      setTimeout(() => {
        this.setState({ notANumber: false });
      }, 5000);
    }
  };
  stepTwoHandleClick = () => {
    let obj = {
      amount: this.state.amount,
    };

    fetch("https://upbeat-wilson-b4adb8.netlify.app/.netlify/functions/api/api/donate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        // console.log(typeof data.client_secret);
        this.setState({ clientSecret: data.client_secret, step: "3" });
      });

    //if not valid show error message to reenter number
  };
  handleChange = e => {
    this.setState({ amount: e.target.value });
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };
  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };
  handleAddressChange = e => {
    this.setState({ address: e.target.value });
  };
  handlePostalChange = e => {
    this.setState({ postalCode: e.target.value });
  };

  handleCityChange = e => {
    this.setState({ city: e.target.value });
  };
  handleStateChange = e => {
    this.setState({ state: e.target.value });
  };

  decreaseStep = e => {
    e.preventDefault();
    this.setState({ step: "1" });
  };
  backToDetails = () => {
    this.setState({ step: "2" });
  };
  successStep = () => {
    this.setState({ step: "success" });
  };
  errorStep = () => {
    this.setState({ step: "error" });
  };
  resetState = () => {
    this.setState({
      amount: "",
      email: "",
      notANumber: false,
      name: "",
      address: "",
      postalCode: "",
      city: "",
      state: "",
      step: "1",
      clientSecret: "",
      queryString: {},
    });
  };

  stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);
  
  render() {
    return (
      <>
        <Head title="TSS donations"></Head>
        <div
          className={donateStyles.donateHeader}
          style={
            this.state.step === "1"
              ? {
                  backgroundImage: `linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,.4)),url(${donateImg})`,
                  height: "50vh",
                }
              : {
                  backgroundImage: `linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,.4)),url(${donateImg})`,
                }
          }
        >
          {this.state.step === "1" ? (
            <div className={donateStyles.donateInnerFirst}>
              <h1>Help us keep our mission alive</h1>
              <p>We are soley funded by donations like yours</p>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {this.state.notANumber === true ? (
                  <div className={donateStyles.errorMessage}>
                    The amount you entered is not a valid amount. Please
                    re-enter an amount.
                  </div>
                ) : null}

                <div className={donateStyles.donateContainerFirst}>
                  <input
                    pattern="^\d+(?:\.\d{0,2})$"
                    type="text"
                    onChange={this.handleChange}
                    placeholder="$20.00"
                    value={
                      this.state.amount.length > 0 ? this.state.amount : ""
                    }
                  ></input>
                  <button
                    disabled={this.state.amount.length > 0 ? false : true}
                    onClick={this.stepOneHandleClick}
                    className={donateStyles.primaryBtnFirst}
                  >
                    Donate
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {this.state.step === "2" ? (
            <div className={donateStyles.donateInner}>
              <h2>Checkout details</h2>
              <div className={donateStyles.donateContainer}>
                <div className={donateStyles.donateContainerItem}>
                  <label>Full Name</label>
                  <input
                    type="text"
                    onChange={this.handleNameChange}
                    placeholder="John Doe"
                    value={this.state.name.length > 0 ? this.state.name : ""}
                  ></input>
                </div>
                <div className={donateStyles.donateContainerItem}>
                  <label>Email</label>
                  <input
                    type="email"
                    onChange={this.handleEmailChange}
                    placeholder="example@gmail.com"
                    value={this.state.email.length > 0 ? this.state.email : ""}
                  ></input>
                </div>
                <div className={donateStyles.donateContainerItem}>
                  <label>Address</label>
                  <input
                    type="text"
                    onChange={this.handleAddressChange}
                    placeholder="1234 Mockingbird Lane"
                    value={
                      this.state.address.length > 0 ? this.state.address : ""
                    }
                  ></input>
                </div>
                <div id={donateStyles.postCodeContainer}>
                  <div id={donateStyles.postCode}>
                    <label>Postal Code</label>
                    <input
                      type="text"
                      onChange={this.handlePostalChange}
                      placeholder="30533"
                      value={
                        this.state.postalCode.length > 0
                          ? this.state.postalCode
                          : ""
                      }
                    ></input>
                  </div>
                  <div id={donateStyles.city}>
                    <label>City</label>
                    <input
                      type="text"
                      onChange={this.handleCityChange}
                      placeholder="Atlanta"
                      value={this.state.city.length > 0 ? this.state.city : ""}
                    ></input>
                  </div>
                  <div id={donateStyles.state}>
                    <label>State</label>
                    <input
                      type="text"
                      onChange={this.handleStateChange}
                      placeholder="Ga"
                      maxLength="2"
                      value={
                        this.state.state.length > 0 ? this.state.state : ""
                      }
                    ></input>
                  </div>
                </div>
                <div id={donateStyles.orderSummaryContainer}>
                  <h2 id={donateStyles.orderSummary}>Order Summary</h2>
                  <span
                    onClick={() => {
                      this.setState({ step: "1" });
                    }}
                  >
                    Change donation amount
                  </span>
                </div>
                <div id={donateStyles.orderContainer}>
                  <p>Total:</p>
                  <p>${this.state.amount}</p>
                </div>
                <button
                  disabled={
                    this.state.email.length > 0 &&
                    this.state.name.length > 0 &&
                    this.state.address.length > 0 &&
                    this.state.postalCode.length > 0 &&
                    this.state.city.length > 0 &&
                    this.state.state.length === 2
                      ? false
                      : true
                  }
                  onClick={this.stepTwoHandleClick}
                  className={donateStyles.primaryBtn}
                >
                  Confirm order
                </button>
              </div>
            </div>
          ) : null}
          {this.state.step === "3" ? (
            <Elements stripe={this.stripePromise}>
              <CheckoutForm
                clientSecret={this.state.clientSecret}
                amount={this.state.amount}
                decreaseStep={this.decreaseStep}
                email={this.state.email}
                name={this.state.name}
                address={this.state.address}
                postalCode={this.state.postalCode}
                backToDetails={this.backToDetails}
                city={this.state.city}
                state={this.state.state}
                successStep={this.successStep}
                errorStep={this.errorStep}
              ></CheckoutForm>
            </Elements>
          ) : null}
          {this.state.step === "success" ? (
            <div id={donateStyles.successContainer}>
              <img
                id={donateStyles.exit}
                src={close}
                onClick={this.resetState}
              ></img>
              <img id={donateStyles.checkMark} src={checkmark}></img>
              <h2>Your order was successful!</h2>
              <h3>Thank you for your donation!</h3>
              <p id={donateStyles.successEmail}>
                Your reciept has been emailed to {this.state.email}
              </p>
              <p id={donateStyles.concerns}>
                If you have and questions or concerns{" "}
                <Link to="/contact-us">contact us</Link>
              </p>
            </div>
          ) : null}
          {this.state.step === "error" ? (
            <div id={donateStyles.successContainer}>
              <img
                id={donateStyles.exit}
                src={close}
                onClick={this.resetState}
              ></img>
              <img id={donateStyles.checkMark} src={errorMark}></img>
              <h2>Oh no! Something went wrong!</h2>
              <h3>Your donation did not process.</h3>
              <p id={donateStyles.successEmail}>
                Please check your information and try again.
              </p>
              <p id={donateStyles.concerns}>
                If you have and questions or concerns{" "}
                <Link to="/contact-us">contact us</Link>
              </p>
            </div>
          ) : null}
        </div>

        <section className={donateStyles.policySection}>
          <h2>Policy Statement </h2>
          <p>
            Two of our financial practices we wish to call to attention:
            <br />
            1. TSS, Inc. intends that funds collected for charitable purposes
            will be used for those purposes. Our financial accounting will be
            done by:
          </p>
          <div>
            <h3>Capital Accounting & Tax LLC</h3>
            <p>
              200 Sandy Springs Place Ste 200
              <br />
              Atlanta, GA 30328
              <br />
              Tel : 404-947-7400 ext 25
              <br />
              Fax :404-329-0446
              <br />
              www.yourwealthtax.com
            </p>
          </div>
          <p>
            Semiannually, we will publish in this section of our website our
            overhead rate for the previous six-month period, certified by
            Capital Accounting. We intend that this will never exceed 15%, and
            if it ever does it will be in response to special circumstances that
            we will describe.
          </p>
          <p>
            2. TSS, Inc. plans to operate a diverse set of PROGRAMS. Donors may
            contribute specifically to any of these, or also to the overall
            enterprise and leave the allocation to us.
          </p>
        </section>
      </>
    );
  }
}
export default DonatePage;
